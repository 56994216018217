import "./style.scss";

import { useTranslation } from "react-i18next/dist/es/useTranslation";
import {
  arrowIcon,
  contactUs,
  detail,
  downloadLink,
  heading,
  insideLink,
  anchorLink,
  downloadFileLink,
  li,
  ol,
  outsideLink,
  paragraph,
  renderCom,
  toCheckout,
  ul,
  withArrowLink,
} from "../../../components/ForMarkDownComponents";

import LearnMore from "../../../components/Sidebar/LearnMore";
import { QuestionsBlock } from "../_supportDocPage";

import TableForDetails from "../../../components/Details/_table";
import BreadcrumbsBlock from "../../../components/Breadcrumbs/BreadcrumbsBlock";
import ToolkitLink from "../../../components/Sidebar/ToolkitLink";
import WithSidebarPage from "..";
import { createClassName } from "../../../helpers";

const cn = createClassName("documentation-details-page");

const detailHeading = ({
  children,
  id,
  fontLevel = 3,
  weight = "normal",
  sidebar,
}) => (
  <div id={sidebar || id} className={cn("heading")}>
    {heading({
      children, id, fontLevel, weight,
    })}
  </div>
);

const detailLi = ({ children, bottomReduce = true }) => li({ children, bottomReduce });

const compareTable = () => (
  <table className={cn("table")}>
    <TableForDetails />
  </table>
);

const previewTitle = ({
 children, id, sidebar, level = 1, fontLevel = 3, weight = "normal",
}) => (
  <div id={sidebar || id} className={cn("heading", { section: true })}>
    {heading({
 children, id, level, fontLevel, weight,
})}
  </div>
);

const renderComToolkit = {
  heading: detailHeading,
  detail,
  p: paragraph,
  ul,
  ol,
  li: detailLi,
  "inside-link": insideLink,
  "outside-link": outsideLink,
  "contact-us": contactUs,
  "to-checkout": toCheckout,
  "with-arrow-link": withArrowLink,
  "compare-table": compareTable,
  "download-link": downloadLink,
  "anchor-link": anchorLink,
  "download-file-link": downloadFileLink,
  arrow: arrowIcon,
  "preview-title": previewTitle,
};

const detailsRenderCom = renderCom(renderComToolkit);

const DocumentationDetailsPage = ({
 frontmatter, htmlAst, allPagesComments, ...props
}) => {
  const { t } = useTranslation("strings");

  const sidebarSubTitle = frontmatter.featureTitleToolkit ? frontmatter.featureTitleToolkit : t("sidebar.featureTitleToolkit");
  const sidebarAfterAccentTitle = props.afterAccentTitleToolkit ? t("common:toolkit") : "";
  return (
    <WithSidebarPage
      seo={frontmatter.seo[0]}
      breadcrums={<BreadcrumbsBlock frontmatter={frontmatter} />}
      headingsClassName=".documentation-details-page__heading"
      sidebarChildrenTitle={frontmatter.sidebarChildrenTitle}
      className={cn({ wrap: "page" })}
      sidebarChildrenBefore={(
        <ToolkitLink
          sidebarSubTitle={sidebarSubTitle}
          href={frontmatter.href}
          featureToolkit={frontmatter.featureToolkit}
          sidebarAfterAccentTitle={sidebarAfterAccentTitle}
        />
      )}
      contentAfterMainSection={props.withComments ? <QuestionsBlock data={allPagesComments.nodes} withComments={props.withComments} title={t("questions-or-suggestions")} /> : undefined}
    >
      {detailsRenderCom(htmlAst)}
    </WithSidebarPage>
  );
};

DocumentationDetailsPage.defaultProps = {
  withComments: true,
};

export default DocumentationDetailsPage;
