import "./style.scss";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import ArrowToRight from "../../Icons/ArrowToRight";
import LinkOnResourceWithIcon from "../../Links/LinkOnResource/_withIcon";
import Title from "../../Title";
import { createClassName } from "../../../helpers";

const cn = createClassName("learnMore");

const LearnMore = (props) => {
  const { t } = useTranslation("strings");
  return (
    <div className={`${cn({ wrap: true })} ${props.className}`}>
      <Title
        level={4}
      >
        <>
          {props.subTitle}
          {" "}
          <span className={cn({ text: "accent" })}>{props.accentTitle}</span>
          {` ${props.afterAccentTitle}`}
        </>
      </Title>
      <LinkOnResourceWithIcon
        href={props.href}
        color="white"
        className={cn({ btn: true })}
        icon={<ArrowToRight color="white" />}
        iconClassName={cn({ btn: "icon" })}
        isTarget
        isVolume
      >
        {props.buttonText ? props.buttonText : t("getStarted.buttonText")}
      </LinkOnResourceWithIcon>
    </div>
  );
};

LearnMore.defaultProps = {
  buttonText: "",
  href: "/support/",
  className: "",
  afterAccentTitle: "",
};

LearnMore.propTypes = {
  accentTitle: PropTypes.string,
  subTitle: PropTypes.string,
  afterAccentTitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LearnMore;
